import React from "react";

const Header = () => (
  <header>
    <div className="row-container">
      <div className="logo-container">
        <img src="img/logo.svg" className="logo-image" alt="Logo" />
        <h1 className="logo-text">
          <span className="big-logo-letter">x</span>Enosfera
        </h1>
      </div>
      <div className="header-buttons-container">
        <ul className="header-buttons-list">
          <li>
            <a href="/">
              <img src="img/language-symbol.svg" alt="language" />
            </a>
          </li>
          <li>
            <a href="/">
              <img src="img/about-symbol.svg" alt="about" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
);

export default Header;
