import React, { useState, useEffect } from "react";
import { CSSTransitionGroup } from "react-transition-group"; // ES6

const Slider = ({ data, setShowFinalModal }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [hideControls, setHideControls] = useState(false);

  useEffect(() => {
    let timeout;
    if (
      data[slideIndex] &&
      data[slideIndex].fearEffect &&
      data[slideIndex].fearEffect === "final"
    ) {
      timeout = setTimeout(() => {
        setShowFinalModal(true);
        clearTimeout(timeout);
      }, 3000);
    }
  });

  useEffect(() => {
    let slideTimeout;
    if (
      data[slideIndex] &&
      data[slideIndex].fearEffect &&
      data[slideIndex].fearEffect === "automate"
    ) {
      setHideControls(true);
      slideTimeout = setTimeout(() => {
        setSlideIndex(slideIndex + 1);
        clearTimeout(slideTimeout);
      }, 3000);
    } else {
    }
  }, [setHideControls, data, slideIndex]);

  return (
    <div className="photo-carousel-container">
      <div id="photoCarousel" className="photo-carousel">
        {slideIndex > 0 && !hideControls && (
          <button
            id="moveUp"
            className="navigation-arrow up"
            onClick={() => setSlideIndex(slideIndex - 1)}
          >
            <img src="img/arrow-up.svg" alt="arrow-up" />
          </button>
        )}

        {slideIndex < data.length - 1 && !hideControls && (
          <button
            id="moveDown"
            className="navigation-arrow down"
            onClick={() => setSlideIndex(slideIndex + 1)}
          >
            <img src="img/arrow-down.svg" alt="arrow-down" />
          </button>
        )}

        <div className="scene" id="carouselScene">
          <CSSTransitionGroup
            transitionName="example"
            transitionEnterTimeout={1300}
            transitionLeaveTimeout={1000}
          >
            {data && data.length && data[slideIndex] && (
              <div
                className="slide"
                id={"slide" + data[slideIndex].id}
                key={data[slideIndex].id}
              >
                <div className="slide-image-container">
                  <img
                    src={data[slideIndex].src}
                    className="slide-image"
                    alt=""
                  />
                </div>
                <div className="slide-text-content">
                  <p className="slide-description">{data[slideIndex].text}</p>
                </div>
              </div>
            )}
          </CSSTransitionGroup>
        </div>
      </div>
    </div>
  );
};

export default Slider;
