import React from "react";

import Slider from "./slider";

const Main = props => (
  <main className="main-content">
    <div className="row-container">
      <Slider {...props} />
    </div>
  </main>
);

export default Main;
