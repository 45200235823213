import React, { useEffect, useState } from "react";

import "./App.css";
import "./styles/styles.css";
import Header from "./components/header";
import Main from "./components/main";
import Modal from "./components/modal";
import Footer from "./components/footer";

const photosData = require("./data/photos.json");

function App() {
  const [sliderData, setSliderData] = useState([]);
  const [showFinalModal, setShowFinalModal] = useState(false);

  useEffect(() => {
    if (photosData.length) {
      setSliderData(photosData);
    }
  }, [sliderData]);

  return (
    <div className="App">
      <div id="root" className="background-container">
        <Header />
        <Main data={sliderData} setShowFinalModal={setShowFinalModal} />
        {showFinalModal && <Modal />}
        <Footer />
      </div>
    </div>
  );
}

export default App;
